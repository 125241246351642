
import { defineComponent, inject, ref, watch } from 'vue';

declare interface IEmergencyNewsHeight {
    value: number
}

const BG_COLOR_OFFSET = 1;
const SCROLL_HIDE_OFFSET = 200;
const SCROLL_DELTA = 50;

export default defineComponent({
    data() {
        return {
            isSearchOpened: false,
            isLangNavOpened: false,
            parentNavItem: ''
        }
    },
    setup() {
        let isHidden = ref(false);
        let isFixed = ref(false);
        let isBgColor = ref(false);
        let lastScrollPosition = window.pageYOffset;

        const emergencyNewsHeight = inject('emergencyNewsHeight') as IEmergencyNewsHeight;

        const checkHeaderPropsOnScroll = () => {
            const pageY = window.pageYOffset;

            if (pageY >= SCROLL_HIDE_OFFSET + emergencyNewsHeight.value) {
                if (Math.abs(pageY - lastScrollPosition) >= SCROLL_DELTA) {
                    isHidden.value = pageY >= lastScrollPosition;
                    lastScrollPosition = pageY;
                }
            } else if (isHidden.value) {
                isHidden.value = false;
            }

            if (pageY >= emergencyNewsHeight.value && !isFixed.value) {
                isFixed.value = true;
            } else if (pageY < emergencyNewsHeight.value && isFixed.value) {
                isFixed.value = false;
            }

            if (pageY >= (BG_COLOR_OFFSET + emergencyNewsHeight.value) && !isBgColor.value) {
                isBgColor.value = true;
            } else if (pageY < (BG_COLOR_OFFSET + emergencyNewsHeight.value) && isBgColor.value) {
                isBgColor.value = false;
            }
        };

        window.addEventListener('scroll', checkHeaderPropsOnScroll);
        watch(emergencyNewsHeight, checkHeaderPropsOnScroll);
        checkHeaderPropsOnScroll();

        return {
            isHidden,
            isFixed,
            isBgColor,
        };
    },
    methods: {
        toggleMobileNav() {
            const mobileNav = document.querySelector('.mobile-nav');
            if (mobileNav) {
                document.body.classList.toggle('mobile-nav-opened');

                // close subnav if mobile nav closed
                if (!document.body.classList.contains('mobile-nav-opened')) {
                    this.closeSubNav();
                }

                // if (document.body.classList.contains('mobile-nav-opened')) { // opening mobile nav
                // const activeNavItems:Array<HTMLElement> = [];
                // Array.from<HTMLAnchorElement>(mobileNav.querySelectorAll('a.active')).forEach((link) => {
                //   const activeNavItem = link.parentElement;
                //   if (activeNavItem && activeNavItem.classList.contains('sub')) {
                //     activeNavItems.push(activeNavItem);
                //   }
                // });
                // activeNavItems.forEach((item) => {
                //   item.classList.add('js-opened');
                //   window.domSlider.slideDown({ element: item.querySelector('nav') });
                // });
                // } else { // closing mobile nav
                // Array.from<HTMLAnchorElement>(mobileNav.querySelectorAll('.js-opened')).forEach((item) => {
                //   item.classList.remove('js-opened');
                //   window.domSlider.slideUp({ element: item.querySelector('nav') });
                // });
                // }
            }
        },

        openSubNav(event: Event) {
            const mobileNav = document.querySelector('.mobile-nav');
            const navItem = (<HTMLElement>event.target).closest('.main-nav-item');
            if (navItem?.classList.contains('sub')) {
                event.preventDefault();
                navItem.classList.add('js-opened');
                navItem.querySelector('nav')?.classList.add('mobile-active');
                this.parentNavItem = (<HTMLElement>navItem.querySelector('.main-nav-link'))?.innerText;
                if (mobileNav) {
                    mobileNav.querySelector('.main-nav-body')?.scrollTo(0,0);
                    mobileNav.classList.add('mobile-nav--subnav-mobile-active');
                }
            }
        },

        closeSubNav(event?: Event) {
            const mobileNav = document.querySelector('.mobile-nav');
            const navItem = this.$el.querySelector('.main-nav-item.js-opened');
            if (navItem?.classList.contains('sub')) {
                event?.preventDefault();
                navItem.classList.remove('js-opened');
                navItem.querySelector('nav')?.classList.remove('mobile-active');
                this.parentNavItem = '';
                mobileNav?.classList.remove('mobile-nav--subnav-mobile-active');
            }
        },

        toggleSearchForm(event: Event) {
            this.isLangNavOpened = false;
            this.isSearchOpened = !this.isSearchOpened;

            if (this.isSearchOpened) {
                this.$nextTick(() => {
                    (<HTMLElement>document.querySelector('.header-search-form .form-control--search'))?.focus();
                });
            }
        },

        toggleLangNav(event: Event) {
            this.isSearchOpened = false;
            this.isLangNavOpened = !this.isLangNavOpened;
        }
    }
})
